<template>
  <div class="waterTransportAddPage">
    <div class="facilityBox">
      <!-- 顶部按钮 -->
      <div class="btns">
        <div class="left">
          <el-button type="primary" :loading="flag.uploadedWaybilling" @click="$refs.waybill.click()">上传运单</el-button>
          <input type="file" @change="uploadWaybill" ref="waybill" style="display:none">
        </div>
        <div class="right">
          <el-button @click="$router.push('/platform/waterTransport/index')">返回</el-button>
          <el-button type="success" :loading="flag.submiting" @click="keepBills">提交资料</el-button>
        </div>
      </div>
      <!-- 可编辑表格 -->
      <vxe-table border resizable show-overflow :data="currentTableList1" :edit-config="{trigger: 'click', mode: 'cell'}">
        <vxe-table-column align="center" field="WaterBillNum" title="运单号" width="150" fixed="left"></vxe-table-column>
        <vxe-table-column align="center" field="Shipper" title="托运人" width="150" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
        <vxe-table-column align="center" field="Carrier" title="承运人" width="150" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
        <vxe-table-column align="center" field="CarrierIDCard" title="承运人身份证号码" width="150" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
        <vxe-table-column align="center" field="ShipsName" title="船名" width="150" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
        <vxe-table-column align="center" field="ShipsType" title="船舶种类" width="150" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
        <vxe-table-column align="center" field="GoodsName" title="货名" width="150" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
        <vxe-table-column align="center" field="Unit" title="单位" width="150" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
        <vxe-table-column align="center" field="Num" title="数量" width="150" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
        <vxe-table-column align="center" field="TotalAmount" title="总金额（元）" width="150" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
        <vxe-table-column align="center" field="StartingPoint" title="起始地" width="150" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
        <vxe-table-column align="center" field="Destination" title="到达地" width="150" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
        <vxe-table-column align="center" field="DepartureTime" title="起运时间" width="150">
          <template #default="{ row }">
            <vxe-input v-model="row.DepartureTime" placeholder="起运时间" type="datetime" transfer></vxe-input>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="ArrivalTime" title="到达时间" width="150">
          <template #default="{ row }">
            <vxe-input v-model="row.ArrivalTime" placeholder="到达时间" type="datetime" transfer></vxe-input>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="InvoiceNumber" title="发票号码" width="150" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
        <vxe-table-column align="center" field="InvoiceTime" title="发票日期" width="150">
          <template #default="{ row }">
            <vxe-input v-model="row.InvoiceTime" placeholder="到达时间" type="date" transfer></vxe-input>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="CreateTime" title="提交时间" width="150"></vxe-table-column>
        <vxe-table-column align="center" field="" title="操作" width="650" fixed="right">
          <template #default="{ row }">
            <el-button type="primary" size="mini" @click="openShipDialog(row)">船舶证照</el-button>
            <el-button type="primary" size="mini" @click="openTrackDialog(row)">轨迹</el-button>
            <el-button type="primary" size="mini" @click="openPersonDialog(row)">联系人</el-button>
            <el-button type="primary" size="mini" @click="openContactDialog(row)">合同</el-button>
            <el-button type="primary" size="mini" @click="openPaymentDialog(row)">付款凭证</el-button>
            <el-button type="primary" size="mini" @click="openInvoiceDialog(row)">发票</el-button>
            <el-button type="primary" size="mini" @click="openOtherDialog(row)">其它凭证</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteWaybill(row)"></el-button>
          </template>
        </vxe-table-column>
      </vxe-table>
      <!-- 分页 -->
      <el-pagination background style="margin: 16px; 0" @current-change="pagination1Change" :current-page.sync="pagination1.page" :page-size="pagination1.pagesize" layout="total, prev, pager, next, jumper" :total="pagination1.total">
      </el-pagination>
    </div>

    <!-- 船舶证照dialog -->
    <el-dialog :visible.sync="flag.shipDialog" v-if="JSON.stringify(currentItem) != '{}'" title="船舶信息" width="1200px">
      <el-form :inline="true" :model="currentItem.shipFormData" label-width="130px">
        <div style="display:flex;">
          <el-form-item prop="shipImg1.picUrl" label="船舶证1">
            <UploadImg class="uploadImg" :loading="currentItem.shipFormData.shipImg1.loading" :uploaded="currentItem.shipFormData.shipImg1.uploaded" :picUrl="currentItem.shipFormData.shipImg1.picUrl" @update:file="updateFile" @change="uploadImg(currentItem.shipFormData.shipImg1, 'Ships')" @deleteImg="deleteImg(currentItem.shipFormData.shipImg1)"></UploadImg>
          </el-form-item>
          <el-form-item prop="shipImg2.picUrl" label="船舶证2">
            <UploadImg class="uploadImg" :loading="currentItem.shipFormData.shipImg2.loading" :uploaded="currentItem.shipFormData.shipImg2.uploaded" :picUrl="currentItem.shipFormData.shipImg2.picUrl" @update:file="updateFile" @change="uploadImg(currentItem.shipFormData.shipImg2, 'Ships')" @deleteImg="deleteImg(currentItem.shipFormData.shipImg2)"></UploadImg>
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="number" label="编号">
            <el-input v-model="currentItem.shipFormData.number"></el-input>
          </el-form-item>
          <el-form-item prop="shipsName" label="船名">
            <el-input v-model="currentItem.shipFormData.shipsName"></el-input>
          </el-form-item>
          <el-form-item prop="formerName" label="曾用名">
            <el-input v-model="currentItem.shipFormData.formerName"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="homePort" label="船籍港">
            <el-input v-model="currentItem.shipFormData.homePort"></el-input>
          </el-form-item>
          <el-form-item prop="shipRegistrationNumber" label="船舶登记号">
            <el-input v-model="currentItem.shipFormData.shipRegistrationNumber"></el-input>
          </el-form-item>
          <el-form-item prop="surveyRegistratioNumber" label="船检登记号">
            <el-input v-model="currentItem.shipFormData.surveyRegistratioNumber"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="shipowner" label="船舶所有人">
            <el-input v-model="currentItem.shipFormData.shipowner"></el-input>
          </el-form-item>
          <el-form-item prop="shipOperator" label="船舶经营人">
            <el-input v-model="currentItem.shipFormData.shipOperator"></el-input>
          </el-form-item>
          <el-form-item prop="operatorPermitNumber" label="经营人许可证号码">
            <el-input v-model="currentItem.shipFormData.operatorPermitNumber"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="serviceLife" label="使用期限">
            <el-date-picker v-model="currentItem.shipFormData.serviceLife" type="date" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          <el-form-item prop="licenceIssuingAuthority" label="发证机关">
            <el-input v-model="currentItem.shipFormData.licenceIssuingAuthority"></el-input>
          </el-form-item>
          <el-form-item prop="dateOfIssue" label="发证日期">
            <el-date-picker v-model='currentItem.shipFormData.dateOfIssue' type="date" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="shipType" label="船舶类型">
            <el-input v-model="currentItem.shipFormData.shipType"></el-input>
          </el-form-item>
          <el-form-item prop="shipMaterial" label="船舶材料">
            <el-input v-model="currentItem.shipFormData.shipMaterial"></el-input>
          </el-form-item>
          <el-form-item prop="shipGrossTonnage" label="船舶总吨">
            <el-input v-model="currentItem.shipFormData.shipGrossTonnage"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="completionDate" label="建成日期">
            <el-date-picker v-model="currentItem.shipFormData.completionDate" type="date" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          <el-form-item prop="redevelopmentDate" label="改建日期">
            <el-date-picker v-model="currentItem.shipFormData.redevelopmentDate" type="date" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          <el-form-item prop="mainMotorPower" label="主机功率">
            <el-input v-model="currentItem.shipFormData.mainMotorPower"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="allowScope" label="船舶经营人许可证核定的经营范围" label-width="230px">
            <el-input v-model="currentItem.shipFormData.allowScope"></el-input>
          </el-form-item>
          <el-form-item prop="stipulateScope" label="本船核定的经营范围" label-width="155px">
            <el-input v-model="currentItem.shipFormData.stipulateScope"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div style="text-align:center">
        <el-button type="primary" size="mini" :loading="flag.shipLoading" @click="flag.shipDialog = false">确认</el-button>
      </div>
    </el-dialog>

    <!-- 承运人证照dialog -->
    <el-dialog :visible.sync="flag.personDialog" v-if="JSON.stringify(currentItem) != '{}'" width="1200px">
      <el-form :inline="true" :model="currentItem.personFormData" label-width="155px">
        <div>
          <el-form-item prop="idCardFront.picUrl" label="身份证正面">
            <UploadImg class="uploadImg" :loading="currentItem.personFormData.idCardFront.loading" :uploaded="currentItem.personFormData.idCardFront.uploaded" :picUrl="currentItem.personFormData.idCardFront.picUrl" @update:file="updateFile" @change="uploadImg(currentItem.personFormData.idCardFront, 'Person')" @deleteImg="deleteImg(currentItem.personFormData.idCardFront)"></UploadImg>
          </el-form-item>
          <el-form-item prop="idCardBack.picUrl" label="身份证反面">
            <UploadImg class="uploadImg" :loading="currentItem.personFormData.idCardBack.loading" :uploaded="currentItem.personFormData.idCardBack.uploaded" :picUrl="currentItem.personFormData.idCardBack.picUrl" @update:file="updateFile" @change="uploadImg(currentItem.personFormData.idCardBack, 'Person')" @deleteImg="deleteImg(currentItem.personFormData.idCardBack)"></UploadImg>
          </el-form-item>
          <el-form-item prop="name" label="承运人姓名">
            <el-input v-model="currentItem.personFormData.name"></el-input>
          </el-form-item>
          <el-form-item prop="idNumber" label="承运人身份证号码">
            <el-input v-model="currentItem.personFormData.idNumber"></el-input>
          </el-form-item>
          <el-form-item prop="issuingAuthority" label="承运人身份证签发机关" label-width="160px">
            <el-input v-model="currentItem.personFormData.issuingAuthority"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="periodOfValidity" label="承运人身份证有效期">
            <el-date-picker v-model="currentItem.personFormData.periodOfValidity" type="date" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          <el-form-item prop="isLongTime" label="身份证是否长期有效">
            <el-checkbox v-model="currentItem.personFormData.isLongTime"></el-checkbox>
          </el-form-item>
        </div>
      </el-form>
      <div style="text-align:center">
        <el-button type="primary" size="mini" :loading="flag.personLoading" @click="flag.personDialog = false">确认</el-button>
      </div>
    </el-dialog>

    <!-- 轨迹证照dialog -->
    <el-dialog :visible.sync="flag.trackDialog" v-if="JSON.stringify(currentItem) != '{}'" width="1000px">
      <div style="display:flex;flex-wrap:wrap">
        <UploadImg class="uploadImg" v-for="(item, index) in currentItem.trackList" :key="index" :loading="item.loading" :uploaded="item.uploaded" :picUrl="item.picUrl" @update:file="updateFile" @change="uploadImg(item, 'Trans')" @deleteImg="deleteImg(item)"></UploadImg>
        <div class="add" @click="currentItem.trackList.push({loading: false, uploaded: false, picUrl: ''})">+</div>
      </div>
      <div style=" text-align:center">
        <el-button type="primary" size="mini" :loading="flag.trackLoading" @click="flag.trackDialog = false">确认</el-button>
      </div>
    </el-dialog>

    <!-- 合同证照dialog -->
    <el-dialog :visible.sync="flag.contactDialog" v-if="JSON.stringify(currentItem) != '{}'" width="1000px">
      <div style="display:flex;flex-wrap:wrap">
        <UploadImg class="uploadImg" v-for="(item, index) in currentItem.contactList" :key="index" :loading="item.loading" :uploaded="item.uploaded" :picUrl="item.picUrl" @update:file="updateFile" @change="uploadImg(item, 'Contract')" @deleteImg="deleteImg(item)"></UploadImg>
        <div class="add" @click="currentItem.contactList.push({loading: false, uploaded: false, picUrl: ''})">+</div>
      </div>
      <div style="text-align:center">
        <el-button type="primary" size="mini" :loading="flag.contactLoading" @click="flag.contactDialog = false">确认</el-button>
      </div>
    </el-dialog>

    <!-- 付款凭证证照dialog -->
    <el-dialog :visible.sync="flag.paymentDialog" v-if="JSON.stringify(currentItem) != '{}'" width="1000px">
      <div style="display:flex;flex-wrap:wrap">
        <UploadImg class="uploadImg" v-for="(item, index) in currentItem.paymentList" :key="index" :loading="item.loading" :uploaded="item.uploaded" :picUrl="item.picUrl" @update:file="updateFile" @change="uploadImg(item, 'PayCertificate')" @deleteImg="deleteImg(item)"></UploadImg>
        <div class="add" @click="currentItem.paymentList.push({loading: false, uploaded: false, picUrl: ''})">+</div>
      </div>
      <div style="text-align:center">
        <el-button type="primary" size="mini" :loading="flag.paymentLoading" @click="flag.paymentDialog = false">确认</el-button>
      </div>
    </el-dialog>

    <!-- 发票dialog -->
    <el-dialog :visible.sync="flag.invoiceDialog" v-if="JSON.stringify(currentItem) != '{}'" width="1000px">
      <div style="display:flex;flex-wrap:wrap">
        <UploadImg class="uploadImg" v-for="(item, index) in currentItem.invoiceList" :key="index" :loading="item.loading" :uploaded="item.uploaded" :picUrl="item.picUrl" @update:file="updateFile" @change="uploadImg(item, 'Invoice')" @deleteImg="deleteImg(item)"></UploadImg>
        <div class="add" @click="currentItem.invoiceList.push({loading: false, uploaded: false, picUrl: ''})">+</div>
      </div>
      <div style="text-align:center">
        <el-button type="primary" size="mini" :loading="flag.invoiceLoading" @click="flag.invoiceDialog = false">确认</el-button>
      </div>
    </el-dialog>

    <!-- 其它证照dialog -->
    <el-dialog :visible.sync="flag.otherDialog" v-if="JSON.stringify(currentItem) != '{}'" width="1000px">
      <div style="display:flex;flex-wrap:wrap">
        <UploadImg class="uploadImg" v-for="(item, index) in currentItem.otherList" :key="index" :loading="item.loading" :uploaded="item.uploaded" :picUrl="item.picUrl" @update:file="updateFile" @change="uploadImg(item, 'OtherCertificate')" @deleteImg="deleteImg(item)"></UploadImg>
        <div class="add" @click="currentItem.otherList.push({loading: false, uploaded: false, picUrl: ''})">+</div>
      </div>
      <div style="text-align:center">
        <el-button type="primary" size="mini" :loading="flag.otherLoading" @click="flag.otherDialog = false">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UploadImg from '@/components/commonCmpt/uploadImg'
import { waterTransExcel, uploadImages, keepBills } from '@/api/platform/waybillManage/waterTransport'
export default {
  data() {
    return {
      flag: {
        uploadedWaybilling: false, //导入运单中
        shipDialog: false, //船舶证照dialog
        shipLoading: false, //船舶证照loading
        trackDialog: false, //轨迹dialog
        trackLoading: false, //轨迹loading
        personDialog: false, //联系人dialog
        kinkmapersonnLoading: false, //联系人loading
        contactDialog: false, //合同dialog
        contactLoading: false, //合同loading
        paymentDialog: false, //付款凭证dialog
        paymentLoading: false, //付款凭证loading
        invoiceDialog: false, //发票dialog
        invoiceLoading: false, //发票loading
        otherDialog: false, //其它凭证dialog
        otherLoading: false, //其它凭证loading
        submiting: false, //提交资料中
      },
      pagination1: {
        page: 1,
        pagesize: 10,
        total: 0
      },
      currentFile: null, //当前正在操作的文件
      currentItem: {}, //当前正在操作的tableList1的哪个元素
      tableList1: [], //表格数据
    }
  },
  methods: {
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file
    },
    //删除文件
    deleteImg(field) {
      field.uploaded = false
      field.loading = false
      field.picUrl = ''
    },
    //上传图片
    uploadImg(field, type) {
      field.loading = true
      let formData = new FormData()
      formData.append('file', this.currentFile)
      switch (type) {
        case 'Ships': //船舶
          formData.append('type', type)
          uploadImages(formData).then(res => {
            field.picUrl = res.ship
          }).finally(() => {
            field.loading = false
            field.uploaded = true
          })
          break
        case 'Trans': //轨迹
          formData.append('type', type)
          uploadImages(formData).then(res => {
            field.picUrl = res.ship
          }).finally(() => {
            field.loading = false
            field.uploaded = true
          })
          break
        case 'Person': //联系人身份证
          formData.append('type', type)
          uploadImages(formData).then(res => {
            field.picUrl = res.ship
          }).finally(() => {
            field.loading = false
            field.uploaded = true
          })
          break
        case 'Contract': //合同
          formData.append('type', type)
          uploadImages(formData).then(res => {
            field.picUrl = res.ship
          }).finally(() => {
            field.loading = false
            field.uploaded = true
          })
          break
        case 'PayCertificate': //付款凭证
          formData.append('type', type)
          uploadImages(formData).then(res => {
            field.picUrl = res.ship
          }).finally(() => {
            field.loading = false
            field.uploaded = true
          })
          break
        case 'Invoice': //发票
          formData.append('type', type)
          uploadImages(formData).then(res => {
            field.picUrl = res.ship
          }).finally(() => {
            field.loading = false
            field.uploaded = true
          })
          break
        case 'OtherCertificate': //其它凭证
          formData.append('type', type)
          uploadImages(formData).then(res => {
            field.picUrl = res.ship
          }).finally(() => {
            field.loading = false
            field.uploaded = true
          })
          break
      }
    },
    //船舶证照dialog
    openShipDialog(row) {
      this.currentItem = row
      this.flag.shipDialog = true
    },
    //轨迹dialog
    openTrackDialog(row) {
      this.currentItem = row
      this.flag.trackDialog = true
    },
    //联系人dialog
    openPersonDialog(row) {
      this.currentItem = row
      this.flag.personDialog = true
    },
    //合同dialog
    openContactDialog(row) {
      this.currentItem = row
      this.flag.contactDialog = true
    },
    //付款信息dialog
    openPaymentDialog(row) {
      this.currentItem = row
      this.flag.paymentDialog = true
    },
    //发票dialog
    openInvoiceDialog(row) {
      this.currentItem = row
      this.flag.invoiceDialog = true
    },
    //其它dialog
    openOtherDialog(row) {
      this.currentItem = row
      this.flag.otherDialog = true
    },
    //删除运单
    deleteWaybill(e) {
      this.$confirm('确认删除该运单吗', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.tableList1.forEach((item, index) => {
          if (item.WaterBillNum === e.WaterBillNum) {
            this.tableList1.splice(index, 1)
          }
        })
        this.$message({
          type: 'success',
          message: '删除成功'
        })
      }).catch()
    },
    //上传运单
    uploadWaybill(e) {
      this.flag.uploadedWaybilling = true
      let formData = new FormData()
      formData.append('file', e.srcElement.files[0] || e.target.files[0])
      waterTransExcel(formData).then(res => {
        this.tableList1 = res.SY.wayBills.map(item => {
          item.shipFormData = { //船舶证照
            shipImg1: { //船舶证1
              loading: false,
              uploaded: false,
              picUrl: '',
            },
            shipImg2: { //船舶证2
              loading: false,
              uploaded: false,
              picUrl: '',
            },
            number: '', //编号
            shipsName: '', //船名
            formerName: '', //曾用名
            homePort: '', //船籍港
            shipRegistrationNumber: '', //船舶登记号
            surveyRegistratioNumber: '', //船检登记号
            shipowner: '', //船舶所有人
            shipOperator: '', //船舶经营人
            operatorPermitNumber: '', //经营人许可证号码
            serviceLife: '', //使用期限
            licenceIssuingAuthority: '', //发证机关
            dateOfIssue: '', //发证日期
            shipType: '', //船舶类型
            shipMaterial: '', //船舶材料
            shipGrossTonnage: '', //船舶总吨
            completionDate: '', //建成日期
            redevelopmentDate: '', //改建日期
            mainMotorPower: '', //主机功率
            allowScope: '', //船舶经营人许可证核定的经营范围
            stipulateScope: '', //本船核定的经营范围
          }
          item.trackList = [{ //轨迹列表
            loading: false,
            uploaded: false,
            picUrl: ''
          }, {
            loading: false,
            uploaded: false,
            picUrl: ''
          }]
          item.personFormData = { //联系人
            idCardFront: {
              loading: false,
              uploaded: false,
              picUrl: '',
            }, //身份证正面
            idCardBack: {
              loading: false,
              uploaded: false,
              picUrl: '',
            }, //身份证反面
            name: '', //承运人姓名
            idNumber: '', //承运人身份证号码
            issuingAuthority: '', //承运人身份证签发机关
            periodOfValidity: '', //承运人身份证有效期
          }
          item.contactList = [{ //合同列表
            loading: false,
            uploaded: false,
            picUrl: ''
          }, {
            loading: false,
            uploaded: false,
            picUrl: ''
          }]
          item.paymentList = [{ //支付凭证列表
            loading: false,
            uploaded: false,
            picUrl: ''
          }, {
            loading: false,
            uploaded: false,
            picUrl: ''
          }]
          item.invoiceList = [{ //发票列表
            loading: false,
            uploaded: false,
            picUrl: ''
          }, {
            loading: false,
            uploaded: false,
            picUrl: ''
          }]
          item.otherList = [{ //其它凭证列表列表
            loading: false,
            uploaded: false,
            picUrl: ''
          }, {
            loading: false,
            uploaded: false,
            picUrl: ''
          }]
          return item
        })
        this.pagination1.total = this.tableList1.length
      }).finally(() => {
        this.$refs.waybill.value = ''
        this.flag.uploadedWaybilling = false
      })
    },
    //提交资料
    keepBills() {
      if (this.tableList1.length === 0) return
      this.flag.submiting = true
      let data = [] //提交给后端的数组
      this.tableList1.forEach(item => {
        let trackList = []
        let paymentList = []
        let contactList = []
        let otherList = []
        let invoiceList = []
        item.shipFormData.shipImg1 = item.shipFormData.shipImg1.picUrl
        item.shipFormData.shipImg2 = item.shipFormData.shipImg2.picUrl
        item.personFormData.idCardFront = item.personFormData.idCardFront.picUrl
        item.personFormData.idCardBack = item.personFormData.idCardBack.picUrl
        item.contactList.forEach(el => {
          if (el.picUrl) {
            contactList.push(el.picUrl)
          }
        })
        item.trackList.forEach(el => {
          if (el.picUrl) {
            trackList.push(el.picUrl)
          }
        })
        item.paymentList.forEach(el => {
          if (el.picUrl) {
            paymentList.push(el.picUrl)
          }
        })
        item.otherList.forEach(el => {
          if (el.picUrl) {
            otherList.push(el.picUrl)
          }
        })
        item.invoiceList.forEach(el => {
          if (el.picUrl) {
            invoiceList.push(el.picUrl)
          }
        })
        data.push({
          WaterBillNum: item.WaterBillNum, //运单号
          Shipper: item.Shipper, //托运人
          Carrier: item.Carrier, //承运人
          ShipsName: item.ShipsName, //船名
          StartingPoint: item.StartingPoint, //起始地
          Destination: item.Destination, //到达地
          DepartureTime: item.DepartureTime, //起运时间
          ArrivalTime: item.ArrivalTime, //到达时间
          GoodsName: item.GoodsName, //货名
          Num: item.Num, //数量
          TotalAmount: item.TotalAmount, //总金额
          CarrierIDCard: item.CarrierIDCard, //承运人身份证号码
          EmergencyContactPhone: item.EmergencyContactPhone, //联系人号码
          InvoiceNumber: item.InvoiceNumber, //发票号码
          InvoiceTime: item.InvoiceTime, //开票日期
          ShipsType: item.ShipsType, //船舶种类
          CreateTime: item.CreateTime, //提交时间
          Unit: item.Unit, //单位
          Ship: { //船舶
            Json: JSON.stringify(item.shipFormData)
          },
          Person: { //联系人
            JsonPerson: JSON.stringify(item.personFormData)
          },
          TransImage: { //轨迹
            ImageUml: trackList,
          },
          HeTongImage: { //合同
            ImageUml: contactList
          },
          CertificImages: { //付款凭证
            ImageUml: paymentList
          },
          OthersCertificateImage: { //其它凭证
            ImageUml: otherList
          },
          InvoiceImage: { //发票
            ImageUml: invoiceList
          },
        })
      })
      keepBills(data).then(res => {
        this.$router.push('/platform/waterTransport/index')
      }).finally(() => {
        this.flag.submiting = false
      })
    },
    //分页回调
    pagination1Change(e) {
      this.pagination1.page = e
    }
  },
  created() {
  },
  computed: {
    currentTableList1() {
      return this.tableList1.slice(((this.pagination1.page - 1) * this.pagination1.pagesize), (this.pagination1.page * this.pagination1.pagesize))
    }
  },
  components: {
    UploadImg
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/variable.scss';
.waterTransportAddPage {
  .btns {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }
  .uploadImg {
    margin: 0 8px 16px 8px;
  }
  .add {
    display: inline-block;
    width: 177px;
    height: 177px;
    line-height: 177px;
    text-align: center;
    border-radius: 5px;
    font-size: 100px;
    cursor: pointer;
    border: 1px dashed $borderGray;
  }
}
</style>
<style>
.waterTransportAddPage .el-input__inner {
  width: 220px;
}
</style>